import { useEffect, useMemo, useState } from "react";
import { Stack } from "@fluentui/react";

import styles from "./Answer.module.css";
import { ConversationItem, AnswerRatingRequest, setAnswerRating } from "../../api";
import { parseAnswer } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import AnswerText from "./AnswerText";
import AnswerCitation from "./AnswerCitation";
import { RatingSystem } from "../RatingSystem";

interface Props {
    conversationItem: ConversationItem;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (url: string) => void;
    answerIndex: number;
}

export const Answer = ({
    conversationItem,
    isSelected,
    isStreaming,
    onCitationClicked,
    answerIndex
}: Props) => {
    const messageContent = conversationItem.response.choices[0].message.content;
    const parsedAnswer = useMemo(() => parseAnswer(messageContent, isStreaming), [conversationItem]);
    const [citationSelected, setCitationSelected] = useState(-1);
    const [answerCompleted, setAnswerCompleted] = useState(false);

    const onAnswerRated = (rating: number) => {
        const request: AnswerRatingRequest = {
            conversationId: conversationItem.conversationId,
            questionId: conversationItem.questionId,
            rating: rating,
            tenantId: String(sessionStorage.getItem("tenant_id")),
            accessToken: String(sessionStorage.getItem("access_token"))
        };

        setAnswerRating(request);
    };

    useEffect(() => {
        if (isStreaming || conversationItem.questionId === "" || answerCompleted) {
            return;
        }
        setAnswerCompleted(true);
    }, [isStreaming]);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack className={styles.answerHeader} horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div className={styles.questionInputButtonsContainer}>
                        <RatingSystem starCount={5} onRating={(rating: number) => onAnswerRated(rating)} hidden={!answerCompleted} />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow className={styles.answerText}>
                <AnswerText
                    parsedAnswer={parsedAnswer}
                    onCitationClicked={onCitationClicked}
                    citationSelected={citationSelected}
                    setCitationSelected={setCitationSelected}
                    answerSelected={isSelected}
                    isStreaming={isStreaming}
                    answerIndex={answerIndex}
                ></AnswerText>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack aria-label="sources" horizontal wrap tokens={{ childrenGap: 5 }}>
                        {parsedAnswer.citations.map((citation, index) => {
                            return (
                                <AnswerCitation
                                    key={self.crypto.randomUUID()}
                                    index={index}
                                    citation={citation}
                                    onCitationClicked={onCitationClicked}
                                    citationSelected={citationSelected}
                                    setCitationSelected={setCitationSelected}
                                    answerSelected={isSelected}
                                />
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};

import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./DropDownSwitcher.module.css";
import { getCategories } from "../../api";
import { customStyles } from "./DropDownSwitcherCustomStyles";

interface DropdownSwitcherProps {
    category: { item: string, label: string };
    setCategory: React.Dispatch<React.SetStateAction<{ item: string, label: string }>>;
    isStreaming: boolean;
    isLoadingStream: boolean;
};

export const DropdownSwitcher = ({ category, setCategory, isStreaming, isLoadingStream }: DropdownSwitcherProps) => {
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

    const fetchCategories = async () => {
        try {
            const categories = await getCategories();
            const options = categories
                .filter(category => category.Enabled)
                .map(category => ({ value: category.RowKey, label: category.Name }));
            setOptions(options);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const onCategoryChange = async (selectedOption: any) => {
        setCategory({ item: selectedOption.value, label: selectedOption.label });
    };
    

    return (
        <div id="categoryDropdown" className={styles.dropdownContainer}>
            <label className={styles.dropdownLabel}>Change Assistant</label>
            <Select
                value={{ value: category.item, label: category.label }}
                onChange={onCategoryChange}
                options={options}
                isLoading={false}
                styles={customStyles}
                isDisabled={isStreaming || isLoadingStream}
            />
        </div>
    );
};

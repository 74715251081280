import {
    AnswerRatingRequest,
    AuthorizationRequest,
    AuthorizationResponse,
    Category,
    ChatAppRequest,
    FreeTrialRequest,
    FreeTrialResponse,
    TenantRequest,
    TenantResponse
} from "./models";
import {CustomError} from "./CustomError";

export async function chatApi(request: ChatAppRequest): Promise<Response> {
    return await fetch("/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": request.accessToken
        },
        body: JSON.stringify(request)        
    });
}

export async function getIsFreeTrial(options: FreeTrialRequest): Promise<FreeTrialResponse> {
    const response = await fetch("/isFreeTrial", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            tenantId: options.tenantId
        })
    });

    const parsedResponse: FreeTrialResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getTenantInfo(options: TenantRequest): Promise<TenantResponse> {
    const response = await fetch("/tenantInfo", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": options.accessToken
        },
        body: JSON.stringify({
            userId: options.userId,
            tenantId: options.tenantId
        })
    });

    const parsedResponse: TenantResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getIsAuthorized(options: AuthorizationRequest): Promise<AuthorizationResponse> {
    const response = await fetch("/isAuthorized", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": options.accessToken
        },
        body: JSON.stringify({
            tenantId: options.tenantId
        })
    });

    const parsedResponse: AuthorizationResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new CustomError(parsedResponse.errorCode, parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;    
}

export async function getCategories() : Promise<Category[]> {
    const response = await fetch("/fetchCategories", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": String(sessionStorage.getItem("access_token")),
            "TenantId": String(sessionStorage.getItem("tenant_id"))
        }
    });
    const parsedResponse: Category[] = await response.json();
    return parsedResponse

}

export async function getExamplesByCategory(category: string): Promise<string[]> {
    const url = new URL("/getExamples", window.location.origin);
    url.searchParams.append("category", category);
    
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": String(sessionStorage.getItem("access_token")),
            "TenantId": String(sessionStorage.getItem("tenant_id"))
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const parsedResponse: string[] = await response.json();
    return parsedResponse;
}


export function setAnswerRating(options: AnswerRatingRequest): Promise<Response> {
    return fetch("/setAnswerRating", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "AccessToken": options.accessToken
        },
        body: JSON.stringify({
            conversationId: options.conversationId,
            questionId: options.questionId,
            rating: options.rating,
            tenantId: options.tenantId
        })
    }); 
}